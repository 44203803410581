import React, { useEffect, useState }  from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Hero from '../modules/HeroC';
import { crearurl } from '../Utils';
import Info from '../modules/InfoC';
import Story from '../modules/StoryC';
import Mapleaflet from '../modules/MapleafletC';
import Storycol from '../modules/StorycolC';




const componente="ruta"

let loaded=false
console.log("cargando loaded "+loaded)
export default function Ruta() {
/********************** */
const baseURL = `https://noderender1.onrender.com/${componente}`;
const [data, setData] = useState({id:0});
let { paramid } = useParams();
useEffect(() => {
      axios.get(baseURL,{params: {nombre: paramid}}).then((response) => {setData(response.data)} ).catch(err => console.log(err));}, [paramid]);
      console.log("dentro de Useeffect")
      console.log(data.id)
      if (data.id !==0){
            loaded=true
      } else {
            loaded=false
      }
      console.log("Ya se cargo Axios y loaded es "+loaded)


/********************* */

{if(loaded){
return(

     

<div className='container-xxl '>
      <br></br>
      <br></br>
 
<h1>Rutas x el mundo</h1>


            <div className='row'>
                  <div className='col-sm-4 p-4 col-lg-8  '>
                      
                        
                        <br></br>
                        <h4 className='text-primary'>{data.info.titulo}</h4>
                        <p>{data.info.descripcion}</p>
                        <p className='fw-bold'>#{data.info.ruta_id}</p>
                        
                  </div>

                  
            </div>

<div className='container-lg '>
  
{data.rutas.map((alfa,i)=>(
  <div className='p-4'>
    
     <Hero 
     imagen={`.././images/gallery/${crearurl(alfa.pais)}/${crearurl(alfa.ciudad)}/${crearurl(alfa.atraccion)}/${crearurl(alfa.atraccion)}.jpeg`}  
     titulo={`${i+1}.- ${alfa.titulo}`}
     subtitulo={alfa.titulo}
     imgstyle={alfa.imgstyle} 

     /> 
     

     <div className='container-xxl shadow p-0'>
<div className='row'>
      <div className='col-sm-4 p-4 col-lg-8  '>
            <h3>Informacion</h3>
            <h2>De Actualidad</h2>
            <br></br>
            <p>{alfa.descripcion}</p>
      </div>

      
</div>
</div>


  
  <div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xxl-6'>
          {alfa.historias.map((infostory,s)=>(

            <Storycol num={s} key={s} story={infostory} />
                
            ))}
  </div>
  <br></br>
  <br></br>
  <br></br>
  <br></br>

    </div> 
    
))}



</div>

</div>

)}
}}
