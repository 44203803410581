
import './App.css';
import Nav from './components/NavC';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './components/HomeC';
import Footer from './components/FooterC';
import Atraccion from './components/AtraccionC';
import Ciudad from './components/CiudadC';
import Pais from './components/PaisC';
import Continente from './components/ContinenteC';
import Apitest from './components/ContinenteC';
import Diccionario from './components/DiccionarioC';
import Ruta from './components/RutaC';
import Articulo from './components/ArticuloC';
import Categoria from './components/CategoriaC';
import Puntos from './components/PuntosC';


function App() {
  return (
  

<Router>
  <Nav />
    <Routes>
      <Route  path="/" element={ <Home/> } />
      <Route  path="/punto/:tipo/:paramid/:titulo" element={<Puntos/>} />

      <Route  path="/continente/:paramid" element={<Continente/>} />
      <Route  path="/pais/:paramid" element={ <Pais/> } />
      <Route  path="/ciudad/:paramid" element={ <Ciudad/> } />
      <Route  path="/atraccion/:paramid2/:paramid" element={<Atraccion/>} />
      <Route  path="/ruta/:paramid" element={<Ruta/>} />
      <Route  path="/articulo/:paramid" element={<Articulo/>} />
      <Route  path="/categoria/:paramid" element={<Categoria/>} />


      <Route  path="/diccionario" element={<Diccionario/>} />
      <Route  path="*" element={<main style={{ padding: "1rem" }}><p>No pudimos encontrar la pagina!</p></main>}/>
      

      <Route  path="/apitest/:paramid" element={<Apitest/>} />
  </Routes>
  
  <Footer />
</Router>

 
  );
}

export default App;
