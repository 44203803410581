import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import icon_granja from 'leaflet/dist/images/granja.png';
import icon_mirador from 'leaflet/dist/images/mirador.png';
import icon_edificio from 'leaflet/dist/images/edificio.png';

import iconShadow from 'leaflet/dist/images/marker-shadow.png';


let marker={}
const style = {
  width: "100%",
  height: "100%"
};

class Mapleafletciudad extends React.Component {
  componentDidMount() {

    let mark=this.props.markerPosition


    var locations = [
      ["LOCATION_1", 11.8166, 122.0942],
      ["LOCATION_2", 11.9804, 121.9189],
      ["LOCATION_3", 10.7202, 122.5621],
      ["LOCATION_4", 11.3889, 122.6277],
      ["LOCATION_5", 10.5929, 122.6325]
    ];
    
    var map = L.map('map').setView(this.props.center, 16);
    let mapLink =
      '<a href="http://openstreetmap.org">OpenStreetMap</a>';
    L.tileLayer(
      'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        
        maxZoom:15,
      }).addTo(map);
   
     

    for (var i = 0; i < mark.length; i++) {
      if (mark[i].categoria==="Castillo"){
        console.log("categoria encontrada")
        console.log(mark[i].atraccion)
        let DefaultIcon = L.icon({
          iconUrl: icon_edificio,
          shadowUrl: iconShadow,
          iconSize:     [40, 40], // size of the icon
          shadowSize:   [40, 40], // size of the shadow
          iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
          shadowAnchor: [0,0],  // the same for the shadow
          popupAnchor:  [20, 0] // point from which the popup should open relative to the iconAnchor
      });
      marker = new L.marker([mark[i].lat , mark[i].lng], {title:<h1>Hola</h1>,icon:DefaultIcon}).bindPopup(<h1>Hola</h1>).addTo(map);
      } else if(mark[i].categoria==="Naturaleza"){
        let DefaultIcon = L.icon({
          iconUrl: icon_mirador,
          shadowUrl: iconShadow,
          iconSize:     [40, 40], // size of the icon
          shadowSize:   [40, 40], // size of the shadow
          iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
          shadowAnchor: [0,0],  // the same for the shadow
          popupAnchor:  [20, 0] // point from which the popup should open relative to the iconAnchor
      });
      marker = new L.marker([mark[i].lat , mark[i].lng], {title:mark[i].atraccion,icon:DefaultIcon}).bindPopup(mark[i].atraccion).addTo(map);
      }else{
        let DefaultIcon = L.icon({
          iconUrl: icon_granja,
          shadowUrl: iconShadow,
          iconSize:     [40, 40], // size of the icon
          shadowSize:   [40, 40], // size of the shadow
          iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
          shadowAnchor: [0,0],  // the same for the shadow
          popupAnchor:  [20, 0] // point from which the popup should open relative to the iconAnchor
      });
      marker = new L.marker([mark[i].lat , mark[i].lng], {title:mark[i].atraccion,icon:DefaultIcon}).bindPopup(mark[i].atraccion).addTo(map);
      }
  
 
     
    }
  }
  /*componentDidUpdate({ markerPosition }) {
    // check if position has changed
    if (this.props.markerPosition !== markerPosition) {
      this.marker.setLatLng(this.props.markerPosition);
    }
  }*/
  
  render() {
    return <div id="map" style={style} />;
  }
}

export default Mapleafletciudad;
