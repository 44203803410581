import React, { useEffect, useState }  from 'react'
import { NavLink, Link, useParams } from "react-router-dom";
import axios from 'axios';


const componente="nav"
export default function Nav() {
/********************** */
    const baseURL = `https://noderender1.onrender.com/${componente}`;
    const [data, setData] = useState([{"id":0,"continente":"","paises":[{"id":0,"pais":"","paisurl":"","flag":"","ciudades":[{"id":0,"ciudad":"","ciudadurl":"","atracciones":[{"id":0,"atraccion":"","atraccionurl":""}]}]}]}])
useEffect(() => {
    axios.get(baseURL).then((response) => {setData(response.data);} ).catch(err => console.log(err));}, []);
/********************* */
return(
<div>

<div className="container-fluid nav-main shadow d-flex justify-content-center">
{data.map((alfa,i)=>(
    <div className="nav-top" key={i}>
            
        <div><button><Link to={`/punto/continente/${alfa.id}/${alfa.continente}`}>{alfa.continente}</Link></button></div>
        <div className="nav-show">
        <div className="nav-content">

                {alfa.paises.map( (beta, j)=>(

            <div className="nav-group" key={j}>
  <div className="nav-pais"> <Link to={`/punto/pais/${beta.id}/${beta.paisurl}`}>
                <img className={`flag50 flag50-${beta.paisurl}`} src=".././images/blank.png"></img>
                <h4>{beta.pais}</h4>
                </Link></div> 
                <div className="nav-ciudad">
                    {beta.ciudades.map((cama, k)=>(

//Atraccion
                            <div className="nav-atraccion">
                <Link className='fw-bold' key={k}to={`/punto/ciudad/${cama.id}/${cama.ciudadurl}`}>{cama.ciudad}</Link>

                                        
                                        {cama.atracciones.map((delta, k)=>(
                                    <Link  key={k}to={`/punto/atraccion/${delta.id}/${delta.atraccionurl}`}>{delta.atraccion}</Link>



                                    
                                    ))}
                                    
                                    </div> 

                
                ))}
                
                </div> 
            </div>
                        ))}


        </div>
        </div>

 

    </div>
    

))}

<div className="nav-top"><button> / </button></div>
<div className="nav-top"><Link to={`/diccionario`}><button>Diccionario</button></Link></div>
<div className="nav-top"><Link to={`/ruta/nueva-zelanda-en-12-dias`}><button>Ruta 1</button></Link></div>
<div className="nav-top"><Link to={`/ruta/esslingen-en-1-dia`}><button>Ruta 2</button></Link></div>
<div className="nav-top"><Link to={`/articulo/castillos-medievales`}><button>Art1</button></Link></div>


</div>
{/*<div className="nav-top"><Link to={`/historia`}><button>Historia</button></Link></div>
<div className="nav-top"><Link to={`/ruta/nuevazelandaen10dias`}><button>Rutas x el mundo</button></Link></div>
*/}



</div>
)}
