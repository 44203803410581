import React, { useEffect, useState }  from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Hero from '../modules/HeroC';
import { crearurl } from '../Utils';
import Info from '../modules/InfoC';
import Story from '../modules/StoryC';
import Mapleaflet from '../modules/MapleafletC';




const componente="articulo"

let loaded=false
console.log("cargando loaded "+loaded)
export default function Articulo() {
/********************** */
const baseURL = `https://noderender1.onrender.com/${componente}`;
const [data, setData] = useState({id:0});
let { paramid } = useParams();
useEffect(() => {
      axios.get(baseURL,{params: {nombre: paramid}}).then((response) => {setData(response.data)} ).catch(err => console.log(err));}, [paramid]);
      console.log("dentro de Useeffect")
      console.log(data.id)
      if (data.id !==0){
            loaded=true
      } else {
            loaded=false
      }
      console.log("Ya se cargo Axios y loaded es "+loaded)


/********************* */

{if(loaded){
return(

     

<div className='container-fluid '>
<h1>Rutas x el mundo</h1>

<div className='container-lg'>
   
{data.articulo.map((alfa,i)=>(
      <div className='d-flex row border border-primary'>
     <div className='col-sm-4 border'>       
 <img width='100%'src={`.././images/gallery/${crearurl(alfa.pais)}/${crearurl(alfa.ciudad)}/${crearurl(alfa.atraccion)}/${crearurl(alfa.atraccion)}.jpeg`}></img> 
 </div>
 
 <div className='col-sm-8 border'>
            {alfa.historias.map((beta,i)=>(
                  <img  width='100' src={`.././images/gallery/${crearurl(beta.pais)}/${crearurl(beta.ciudad)}/${crearurl(beta.atraccion)}/${crearurl(beta.historia)}.jpeg`}></img>
            ))}
            </div>

      </div>
))}




</div>
</div>

)}
}}
