import React from "react";
import { Link } from "react-router-dom";
import { crearurl } from '../Utils';

export default function Story(prop) {
let pos="end"
let place=""
       if(prop.num % 2 == 0) {
        pos="start";
        
}
else{
        place="order-sm-first"
}

 


        
/**PANORAMIC ****************************************/
if (prop.story.imgstyle==="p"  ) {return(
<React.Fragment>
        <div key={prop.num} className={`row  py-1 justify-content-${pos}`}>
                <div className="story-div-container col-sm-11">
                        <div className={`row  justify-content-${pos} p-0`}>
                                <div  className="col-sm-11 col-lg-10 p-0" >    
                                        <img className="story-img-100 shadow"   alt={crearurl(prop.story.titulo)} src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img>
                                </div>          
                        <div  className={` col-sm-10 col-lg-6  py-3 px-1`} >   
                             <h4>{prop.story.historia}</h4>
                             <p>Este lugar tiene muchisimas historia y ha sido sede de muchos eventos durante los anios ede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los anios </p>
                        </div>
              </div>
      </div>
      </div>
</React.Fragment>
)}
/******************************************/
/**RECTANGULAR ****************************************/
if (prop.story.imgstyle==="r"  ) {return(
<React.Fragment>
        <div key={prop.num} className={`row  py-1 justify-content-${pos}`}>
                <div className="story-div-container col-sm-11">
                        <div className={`row  justify-content-${pos} p-0`}>
                                <div  className="col-sm-9 col-lg-8 p-0" >    
                                        <img className="story-img-100 shadow"   alt={crearurl(prop.story.titulo)} src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img>
                                </div>          
                        <div  className={` col-sm-6 col-lg-4  py-3 px-1 ${place} `} >   
                             <h4>{prop.story.historia}</h4>
                             <p>Este lugar tiene muchisimas historia y ha sido sede de muchos eventos durante los anios ede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los anios </p>
                        </div>
              </div>
      </div>
      </div>
</React.Fragment>
)}
/******************************************/
/**SQUARE ****************************************/
if (prop.story.imgstyle==="s"  ) {return(
        <React.Fragment>
        <div key={prop.num} className={`row  py-1 justify-content-${pos}`}>
      <div className="story-div-container col-sm-11 col-md-10 col-lg-9 col-xl-8">
      
      
              <div className={`row justify-content-${pos} p-0`}>
                     
                      <div  className="col-10 col-sm-6  p-0" >    
                              <img className="story-img-100 shadow"   alt={crearurl(prop.story.titulo)} src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img>
                      
                      </div>
                      
                      <div  className={` col-sm-6 col-lg-4  py-3 px-1 ${place} `} >   
                             <h4>{prop.story.historia}</h4>
                             <p>Este lugar tiene muchisimas historia y ha sido sede de muchos eventos durante los anios ede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los anios </p>
                      
                      </div>

              </div>
              
      


      </div>

      </div>

      </React.Fragment>
)}
/******************************************/
/**VERTICAL ****************************************/
if (prop.story.imgstyle==="v"  ) {return(
       <React.Fragment>
          <div key={prop.num} className={`row  py-1 justify-content-${pos}`}>
                <div className="story-div-container col-sm-11 col-md-10 col-lg-9 col-xl-8">
                        <div className={`row justify-content-${pos} p-0`}>
                                <div  className="col-8 col-sm-5  p-0" >    
                                        <img className="story-img-100 shadow"   alt={crearurl(prop.story.titulo)} src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img>
                                </div>
                        <div  className={` col-sm-6 col-lg-4  py-3 px-1 ${place} `} >    
                               <h4>{prop.story.historia}</h4>
                               <p>Este lugar tiene muchisimas historia y ha sido sede de muchos eventos durante los anios ede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los aniosede de muchos eventos durante los anios </p>
                        </div>
                </div>
        </div>
        </div>
        </React.Fragment>
       
       
)}
/******************************************/




/***END */
}