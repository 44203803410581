import React, { useEffect, useState }  from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { crearurl } from '../Utils';






let loaded=false
console.log("cargando loaded "+loaded)

export default function Diccionario() {
/********************** */
const baseURL = `https://noderender1.onrender.com/diccionario`;
const [data, setData] = useState([]);
let { paramid } = useParams();
useEffect(() => {
      axios.get(baseURL).then((response) => {setData(response.data)} ).catch(err => console.log(err));}, [paramid]);
      console.log("dentro de Useeffect")
      if (data.length !==0){
            loaded=true
      } else {
            loaded=false
      }
      console.log("Ya se cargo Axios y loaded es "+loaded)


/********************* */

{if(loaded){
return(

<div className='container-xxl'>
      <h1>Diccionario</h1>
      <div className='d-flex flex-wrap'>
{data.map((diccio, d)=>(

<Link className='p-2 fs-3 m-2 border border-primary ' key={d} to={`/punto/${crearurl(diccio.tipo)}/${diccio.id}/${crearurl(diccio.nombre)}`}>{diccio.nombre}</Link>
        
        
      ))}
</div>

</div>
           

)}

}}
