import React from "react";
import { Link } from "react-router-dom";
import { crearurl } from '../Utils';

export default function Storycol(prop) {
let pos="end"
let place="order-0"
let align='start'
let justify=""
let justifyh=""
       if(prop.num % 2 == 0) {
        pos="start";
        justifyh="justify-content-end"
        justify='justify-content-center'
        
}
else{
        align="end"
        place="order-2"
        
}
/************************************************************** */
/************************************************************** */
/************************************************************** */
 

        
/**PANORAMIC ****************************************/
if (prop.story.imgstyle==="p"  ) {return(

<React.Fragment>
<div className='col p-1 test-size d-flex flex-column'>
        <div className='h-50 w-100 order-1' ><img className='test-img-full' src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}/></div>
        <div className={`mx-2 my-1 h-50 w-100   d-flex flex-column ${justifyh} ${place}`}>
        
                <div className={`d-flex flex-column `}>
                    <div className="order-1 p-0" ><h4 className="fs-5 fw-bold p-0" >   {prop.story.historia}</h4> </div>
                {/*<div className={`${place}`}><p className="test-text ">Y aqui sucedieron muchas cosas impresionantes que me encantaria platicarles</p></div>*/}
                </div>

       
    </div>
    </div>
</React.Fragment>
/*
<React.Fragment>
        
                <div key={prop.num} className="storycol-div-container p-1  position-relative col">
                <Link to={`/atraccion/${crearurl(prop.story.atraccion)}`}>     
                <div className="d-flex flex-column p-0">

                        <img className="storycol-img-100"   alt={crearurl(prop.story.titulo)} src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img>
                        <div  className="" >   
                             <div className="fs-6">{prop.story.historia}</div>
                        </div>

                </div>
                        </Link>
              </div>

      
</React.Fragment>*/

)}
/******************************************/
/**RECTANGULAR ****************************************/
if (prop.story.imgstyle==="r"  ) {return(

<React.Fragment>
<div className='col p-1 test-size'>
        <div className='h-100 w-100'><img className='test-img-full'  src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img></div>
       
    </div>
</React.Fragment>


/*<React.Fragment>
        
        <div key={prop.num} className="storycol-div-container p-1  position-relative col">
        <Link to={`/atraccion/${crearurl(prop.story.atraccion)}`}>     
        <div className="d-flex flex-column p-0 position-relative">

                <img className="storycol-img-100"   alt={crearurl(prop.story.titulo)} src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img>
                <div  className="position-absolute top-0 fw-bold text-white" >   
                     <div className="fs-6">{prop.story.historia}</div>
                </div>

        </div>
                </Link>
      </div>


</React.Fragment>*/
)}
/******************************************/
/**SQUARE ****************************************/
if (prop.story.imgstyle==="s"  ) {return(


        <div className=' col p-1 test-size d-flex'>
        <div className='h-100 w-100 position-relative' >
                <img className='test-img-full ' src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img>
                <div className=" position-absolute top-0  d-flex flex-column container justify-content-center story-fondo-filtro">
                        <h4 className="text-white fs-4 text-center">{prop.story.historia}</h4></div>
        </div>
     
       
    </div>

/*<React.Fragment>
        
        <div key={prop.num} className="storycol-div-container p-1  position-relative col">
        <Link to={`/atraccion/${crearurl(prop.story.atraccion)}`}>     
        <div className="row p-0">

                <div className="col-8">
                <img className="storycol-img-100"   alt={crearurl(prop.story.titulo)} src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img>
                </div>
              
                <div  className={`col-4 p-0`} >     
                     <div className="fs-6 ">{prop.story.historia}</div>
                </div>

        </div>
                </Link>
      </div>


</React.Fragment>*/
)}
/******************************************/
/**VERTICAL ****************************************/
if (prop.story.imgstyle==="v"  ) {return(

<React.Fragment>
        <div className=' col p-1 test-size d-flex'>
        <div className='h-100 w-50 order-1' ><img className='test-img-full' src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img></div>
        <div className={`my-3 mx-1 h-100 w-50 ${place}`}>
                <div className={` d-flex p-0  ${justify} `}><h4 className={`fs-6 w-50 fw-bold p-0  `}>{prop.story.historia}</h4></div>
                {/*<p className="test-text">Y aqui sucedieron muchas cosas impresionantes que me encantaria platicarles</p>*/}
        </div>

       
    </div>
</React.Fragment>
    
    /*
<React.Fragment>
        
        
        <div key={prop.num} className=" p-1 storycol-div-container position-relative col">
        <Link to={`/atraccion/${crearurl(prop.story.atraccion)}`}>     
        <div className="row  p-0">

                <div className="col-7 storycol-div-container">
                <img className="storycol-img-r"   alt={crearurl(prop.story.titulo)} src={`.././images/gallery/${crearurl(prop.story.pais)}/${crearurl(prop.story.ciudad)}/${crearurl(prop.story.atraccion)}/${crearurl(prop.story.historia)}.jpeg`}></img>
                </div>
              
                <div  className={`col-5 p-0  ${place}`} >   
                     <div className="fs-6">{prop.story.historia}</div>
                </div>

        </div>
                </Link>
      </div>


</React.Fragment>*/
       
       
)}
/******************************************/




/***END */
}