/*Crear URL para links*/
export const crearurl = (valor1)=>{
 let valor=String(valor1)
    return(valor.replace(/\s+/g, '-').toLowerCase())
  }

  /*Random*/
export const random = (min, max)=>{
  let resultado=min + (Math.random() * (max-min))
      return(Math.round(resultado))
    }


   