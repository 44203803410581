import React, { useEffect, useState }  from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Hero from '../modules/HeroC';
import { crearurl } from '../Utils';
import Info from '../modules/InfoC';
import Story from '../modules/StoryC';
import Mapleaflet from '../modules/MapleafletC';
import Top from '../modules/TopC';




const componente="categoria"
let infohistoria=[]
let infotop=[]
let loaded=false
console.log("cargando loaded "+loaded)
export default function Categoria() {
/********************** */
const baseURL = `https://noderender1.onrender.com/${componente}`;
const [data, setData] = useState({id:0});
let { paramid } = useParams();
useEffect(() => {
      axios.get(baseURL,{params: {nombre: paramid}}).then((response) => {setData(response.data);infotop=response.data.top;infohistoria=response.data.historia;} ).catch(err => console.log(err));}, [paramid]);
      console.log("dentro de Useeffect")
      console.log(data.id)
      if (data.id !==0){
            loaded=true
      } else {
            loaded=false
      }
      console.log("Ya se cargo Axios y loaded es "+loaded)


/********************* */

{if(loaded){
return(

     
           
            
            
         
<div className='conatiner-fluid'>

<br></br>
<br></br>
<h1>{paramid}s del mundo</h1>   

   
   
<div className='container-xxl d-flex justify-content-end'>
<div className="container-xl border  d-flex flex-wrap">
{infotop.map((top)=>(

<Link to={`/atraccion/${crearurl(top.atraccion)}`}>
        <div className="position-relative bg-light shadow m-2 p-2 " >
        
        
        <img  className={`top-image-atraccion`} alt={crearurl(top.titulo)} src={`.././images/gallery/${crearurl(top.pais)}/${crearurl(top.ciudad)}/${crearurl(top.atraccion)}/${crearurl(top.atraccion)}.jpeg`}></img>
       
        </div>
</Link>
  
))}
</div>
</div>
<Story story={infohistoria}
  />

    
    <h1>{data.ciudad}</h1>
      <p>{data.pais}</p>
      <p>{data.id}</p>
      <p>{data.titulo}</p>
      <p>{data.descripcion}</p>
      <p>{data.continente}</p>



     
</div>

)}
}}
