import React, { useEffect, useState }  from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Hero from '../modules/HeroC';
import Mapleaflet from '../modules/MapleafletC';



const baseURL = "https://noderender1.onrender.com";
const componente="home"

export default function Home() {
/********************** */
    const [data, setData] = useState({});
    let { paramid } = useParams();
//useEffect(() => {
   // axios.get(baseURL,{params: {nombre: paramid, tipo: componente}}).then((response) => {setData(response.data[0]);  } ).catch(err => console.log(err));}, []);
/********************* */


function getRandomSize(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }
  
  var allImages = "";
  
  for (var i = 0; i < 25; i++) {
    var width = getRandomSize(200, 400);
    var height =  getRandomSize(200, 400);
    allImages += '<img src="https://placekitten.com/'+width+'/'+height+'" alt="pretty kitty">';
  }
  
 
  /*Map testing*/
let mapa=[[6,8],[114,8],[126,8],[138,8],[150,8],[162,8],[6,16],[90,16],[102,16],[126,16],[138,16],[150,16],[162,16],[294,16],[30,24],[78,24],[102,24],[114,24],[138,24],[150,24],[162,24],[246,24],[270,24],[282,24],[294,24],[30,32],[42,32],[54,32],[66,32],[78,32],[90,32],[114,32],[138,32],[150,32],[174,32],[198,32],[210,32],[234,32],[246,32],[258,32],[270,32],[282,32],[294,32],[306,32],[318,32],[330,32],[342,32],[354,32],[30,40],[42,40],[54,40],[66,40],[78,40],[90,40],[114,40],[138,40],[198,40],[210,40],[222,40],[234,40],[246,40],[258,40],[270,40],[282,40],[294,40],[306,40],[318,40],[330,40],[342,40],[354,40],[54,48],[66,48],[78,48],[90,48],[114,48],[174,48],[186,48],[198,48],[210,48],[222,48],[234,48],[246,48],[258,48],[270,48],[282,48],[294,48],[306,48],[318,48],[342,48],[66,56],[78,56],[90,56],[102,56],[114,56],[174,56],[186,56],[198,56],[210,56],[222,56],[234,56],[246,56],[258,56],[270,56],[282,56],[294,56],[306,56],[318,56],[66,64],[78,64],[90,64],[102,64],[174,64],[222,64],[234,64],[246,64],[258,64],[270,64],[282,64],[294,64],[306,64],[318,64],[66,72],[78,72],[114,72],[174,72],[186,72],[198,72],[210,72],[222,72],[234,72],[246,72],[258,72],[270,72],[282,72],[294,72],[78,80],[90,80],[102,80],[174,80],[186,80],[198,80],[210,80],[234,80],[258,80],[270,80],[282,80],[294,80],[102,88],[114,88],[174,88],[186,88],[198,88],[210,88],[222,88],[234,88],[258,88],[294,88],[306,88],[102,96],[114,96],[126,96],[138,96],[150,96],[198,96],[210,96],[222,96],[282,96],[294,96],[306,96],[330,96],[114,104],[126,104],[138,104],[198,104],[210,104],[222,104],[306,104],[318,104],[114,112],[126,112],[138,112],[198,112],[210,112],[222,112],[234,112],[306,112],[318,112],[330,112],[114,120],[126,120],[210,120],[306,120],[318,120],[330,120],[114,128],[330,128],[354,128],[114,136],[354,136]]

return(
<div className='container-fluid p-0 m-0 bg-light'>

<Hero imagen={".././images/background_mainpage1.jpeg"} 
          titulo={"Bienvenidos A esta pagina increible"} 
          imgstyle={"p"} />

<br></br>


<h1>Historia1</h1>
<br></br>


<div className='container-lg bg-light shadow p-3 p-0 test-col-img'>
    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xxl-5 border-warning'>
        {/*Start Columns*/}
        <div className='col  p-1 d-flex flex-column'>
            <img src='.././images/gallery/europa.jpeg'/>
            <div className='test-col-div  p-0'>
                <h4 className='fs-6 p-0 '>Jugndo mas hermoso de Queenstown</h4>
              
            </div>  
        </div>

        
        <div className='col  p-1 d-flex flex-column'>
            <img src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'/>
        </div>

        <div className='col  p-1'>
            <div className='row'>
                <div className='col'>
                <img src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>     
                </div>
                <div className='col p-0'>
                        <h4 className='fs-6 p-0 '>El teleferico aqui estamosmas hermoso de Queenstown</h4>
                     
                </div>
            </div>
        </div>


        <div className='col  p-1 d-flex flex-column'>
           <img src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'></img> 
           <div className='test-col-div w-75 p-0'>
               <h4 className='fs-6  '>El mirador mas hermoso de Queenstown</h4>
              
            </div>          
        </div>
    
        <div className='col  p-1 d-flex flex-column'>
            <img src='.././images/gallery/alemania/esslingen/esslingen.jpeg'/>
        </div>

        <div className='col  p-1'>
            <div className='row'>
                <div className='col'>
                <img src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>     
                </div>
                <div className='col p-0'>
                <h4 className='fs-6  '>El teleferico aqui estamosmas hermoso de Queenstown</h4>
                       
                    </div>
            </div>
        </div>

        <div className='col-lg-4 col-sm-6 p-1 d-flex flex-column'>
           <img src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'></img> 
           <div className='test-col-div w-75 p-0'>
               <h4 className='fs-6  '>El mirador mas hermoso de Queenstown</h4>
             
            </div>          
        </div>

        <div className='col-lg-4 col-sm-6 p-1 d-flex flex-column'>
           <img src='.././images/gallery/nueva-zelanda/queenstown/queenstown.jpeg'></img>        
        </div>


    </div>

    <br></br>

</div>



<br></br>
<h1>Historia 2.5</h1>
<div className='container xxl d-flex flex-wrap justify-content-center'>
    <div className='test-size'>
        <div className='h-50 w-100' ><img className='test-img-full' src='.././images/gallery/europa.jpeg'/></div>
        <div className='h-50 w-100 d-flex'>
            <div className='h-100 w-25'>    <img className='test-img-full'  src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'/></div>
            <div className='h-100 w-75'>    <img className='test-img-full'   src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'/></div>
        </div>
       
    </div>
    <div className='test-size'>
        <div className='h-75 w-100'><img className='test-img-full'  src='.././images/gallery/alemania/alemania.jpeg'/></div>
       
    </div>


    <div className='test-size d-flex'>
        <div className='h-100 w-75' ><img className='test-img-full' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'/></div>
        <div className='h-100 w-25'>
            <div className='h-75 w-100'>    <img className='test-img-full'  src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'/></div>
           
        </div>
       
    </div>

    <div className='test-size d-flex'>
        <div className='h-100 w-50' ><img className='test-img-full' src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'/></div>
        <div className='h-100 w-50'>
            <div className='h-50 w-100'>    <img className='test-img-full'  src='.././images/gallery/nueva-zelanda/queenstown/queenstown.jpeg'/></div>
            
            <div className='h-50 w-100'>    <img className='test-img-full'  src='.././images/gallery/nueva-zelanda/rotorua/te-puia/te-puia.jpeg'/></div>
        </div>
       
    </div>


    <div className='test-size'>
        <div className='h-50 w-100' ><img className='test-img-full' src='.././images/gallery/europa.jpeg'/></div>
        <div className='h-50 w-100 d-flex'>
            <div className='h-100 w-25'>    <img className='test-img-full'  src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'/></div>
            <div className='h-100 w-75'>    <img className='test-img-full'   src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'/></div>
        </div>
       
    </div>

    <div className='test-size'>
        <div className='h-75 w-100'><img className='test-img-full'  src='.././images/gallery/alemania/alemania.jpeg'/></div>
       
    </div>





</div>


<br></br>

<h1>Historia3</h1>
<br></br>


<div className='container-xxl bg-light shadow p-3 p-0 test-col-img'>
    <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-4 border-warning'>
        {/*Start Columns*/}
        <div className='col  p-1 d-flex flex-column'>
            <img src='.././images/gallery/europa.jpeg'/>
            <div className='test-col-div  p-0'>0
                <h2 className='fs-4 fw-bold '>Jugndo mas hermoso de Queenstown</h2>
                <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
            </div>  
        </div>

        
        <div className='col  p-1 d-flex flex-column'>
            <img src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'/>
        </div>
   
        <div className='col  p-1'>
            <div className='row'>
                <div className='col'>
                <img src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>     
                </div>
                <div className='col p-0'>
                        <h2 className='fs-4 fw-bold '>El teleferico aqui estamosmas hermoso de Queenstown</h2>
                        <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
                </div>
            </div>
        </div>


        <div className='col  p-1 d-flex flex-column'>
           <img src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'></img> 
           <div className='test-col-div w-75 p-0'>
               <h2 className='fs-4 fw-bold '>El mirador mas hermoso de Queenstown</h2>
               <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
            </div>          
        </div>
    
        <div className='col  p-1 d-flex flex-column'>
            <img src='.././images/gallery/alemania/esslingen/esslingen.jpeg'/>
        </div>

        <div className='col  p-1'>
            <div className='row'>
                <div className='col'>
                <img src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>     
                </div>
                <div className='col p-0'>
                <h2 className='fs-4 fw-bold '>El teleferico aqui estamosmas hermoso de Queenstown</h2>
                        <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
                    </div>
            </div>
        </div>

        <div className='col-lg-4 col-sm-6 p-1 d-flex flex-column'>
           <img src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'></img> 
           <div className='test-col-div w-75 p-0'>
               <h2 className='fs-4 fw-bold '>El mirador mas hermoso de Queenstown</h2>
               <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
            </div>          
        </div>

        <div className='col-lg-4 col-sm-6 p-1 d-flex flex-column'>
           <img src='.././images/gallery/nueva-zelanda/queenstown/queenstown.jpeg'></img>        
        </div>


    </div>

    <br></br>

</div>


<br></br>



<br></br>




        <div className='container-fluid bg-dark'> <h1>grid</h1></div>
<h1>Grid</h1>

<div className='container-xxl'>
<section id="photos">
            <img  src='.././images/gallery/europa.jpeg'></img>
            <img  src='.././images/gallery/alemania/alemania.jpeg'></img>
            <img  src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img  src='.././images/gallery/europa.jpeg'></img>
            <img  src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>
            <img  src='.././images/gallery/alemania/esslingen/la-muralla-burg/la-muralla-burg.jpeg'></img>
            <img  src='.././images/gallery/oceania.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img  src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/rotorua/rotorua.jpeg'></img>
            <img  src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/rotorua/te-puia/te-puia.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/queenstown/queenstown.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/queenstown/queenstown.jpeg'></img>
            <img  src='.././images/gallery/europa.jpeg'></img>
            <img  src='.././images/gallery/alemania/alemania.jpeg'></img>
            <img  src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img  src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>
            <img  src='.././images/gallery/alemania/esslingen/la-muralla-burg/la-muralla-burg.jpeg'></img>
            <img  src='.././images/gallery/oceania.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/rotorua/rotorua.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/rotorua/te-puia/te-puia.jpeg'></img>
            <img  src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'></img>
            <img  src='.././images/gallery/nueva-zelanda/queenstown/queenstown.jpeg'></img>

</section>
</div>

<br></br>




<br></br>
<div className='container-fluid bg-dark'> <h1>grid</h1></div>
<h1>Testing</h1>




<br></br>
<div className='container-sm'>
    <div className='row p-0'>
        <div className='p-0 col-xl-3 col-lg-4 col-sm-6'>
            <div className='container-fluid border d-flex flex-wrap justify-content-center p-0'>
            <img className='img-all img-p p-1' src='.././images/gallery/europa.jpeg'></img>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
        
            </div>

        </div>
        <div className='p-0 col-xl-3 col-lg-4 col-sm-6'>
            <div className='container-fluid border d-flex flex-wrap p-0'>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
        
            <img className='img-all img-p p-1' src='.././images/gallery/europa.jpeg'></img>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
           
            </div>  
            
        </div>

        <div className='p-0 col-xl-3 col-lg-4 col-sm-6'>
            <div className='container-fluid border d-flex flex-wrap p-0'>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img className='img-all img-p p-1' src='.././images/gallery/nueva-zelanda/queenstown/queenstown.jpeg'></img>
           
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
        
            </div>  
            
        </div>

        <div className='p-0 col-xl-3 col-lg-4 col-sm-6'>
            <div className='container-fluid border d-flex flex-wrap p-0'>
            <img className='img-all img-p p-1' src='.././images/gallery/europa.jpeg'></img>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
        
            </div>  
            
        </div>

        <div className='p-0 col-xl-3 col-lg-4 col-sm-6'>
            <div className='container-fluid border d-flex flex-wrap p-0'>
            <img className='img-all img-p p-1' src='.././images/gallery/europa.jpeg'></img>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img className='img-all img-r p-1' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
            <img className='img-all img-s p-1' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'></img>
        
            </div>  
            
        </div>
    </div>
</div>

<br></br>

<br></br>

<h1>Historia2</h1>
<br></br>


<div className='container-xxl bg-light shadow p-3 p-0 test-col-img'>
    <div className='row  border-warning'>
        {/*Start Columns*/}
        <div className=' col-sm-7  col-md-8 col-lg-9 col-xl-10  p-1 d-flex flex-column'>
           <img className='test-img-history2' src='.././images/gallery/europa.jpeg'/>
            <div className='test-col-div  p-0'>
                <h2 className='fs-4 fw-bold '>Jugndo mas hermoso de Queenstown</h2>
                <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
            </div>  
        </div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>

        
        <div className=' col-sm-7  col-md-8 col-lg-9 col-xl-10 p-1 d-flex flex-column'>
           <img className='test-img-history2' src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'/>
        </div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>

        <div className='col-sm-7  col-md-8 col-lg-9 col-xl-10 p-1'>
            <div className='row'>
                <div className='col'>
               <img className='test-img-history2' src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>     
                </div>
                <div className='col p-0'>
                        <h2 className='fs-4 fw-bold '>El teleferico aqui estamosmas hermoso de Queenstown</h2>
                        <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
                </div>
            </div>
        </div>

        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>

        <div className='col-sm-7  col-md-8 col-lg-9 col-xl-10  p-1 d-flex flex-column'>
          <img className='test-img-history2' src='.././images/gallery/nueva-zelanda/nueva-zelanda.jpeg'></img> 
           <div className='test-col-div w-75 p-0'>
               <h2 className='fs-4 fw-bold '>El mirador mas hermoso de Queenstown</h2>
               <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
            </div>          
        </div>

        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>

        <div className='col-sm-7  col-md-8 col-lg-9 col-xl-10  p-1 d-flex flex-column'>
           <img className='test-img-history2' src='.././images/gallery/alemania/alemania.jpeg'/>
        </div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>


        <div className='col-sm-7  col-md-8 col-lg-9 col-xl-10  p-1 d-flex flex-column'>
          <img className='test-img-history2' src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'></img> 
           <div className='test-col-div w-75 p-0'>
               <h2 className='fs-4 fw-bold '>El mirador mas hermoso de Queenstown</h2>
               <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
            </div>          
        </div>
    
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>


        <div className='col-sm-7  col-md-8 col-lg-9 col-xl-10  p-1 d-flex flex-column'>
           <img className='test-img-history2' src='.././images/gallery/alemania/esslingen/esslingen.jpeg'/>
        </div>

          
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>


        <div className='col-sm-7  col-md-8 col-lg-9 col-xl-10  p-1'>
            <div className='row'>
                <div className='col'>
               <img className='test-img-history2' src='.././images/gallery/alemania/esslingen/rathaus/rathaus.jpeg'></img>     
                </div>
                <div className='col p-0'>
                <h2 className='fs-4 fw-bold '>El teleferico aqui estamosmas hermoso de Queenstown</h2>
                        <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
                    </div>
            </div>
        </div>

          
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>

        <div className='col-sm-7  col-md-8 col-lg-9 col-xl-10 p-1 d-flex flex-column'>
          <img className='test-img-history2' src='.././images/gallery/nueva-zelanda/queenstown/skylift/skylift.jpeg'></img> 
           <div className='test-col-div w-75 p-0'>
               <h2 className='fs-4 fw-bold '>El mirador mas hermoso de Queenstown</h2>
               <p className='test-text'>Llegamos a este mirador rayando pero pudimos apreciar el atardecer, a pesasr de que el clima estaba nublado y lluuvioso, pudimos aprovechar</p>
            </div>          
        </div>

          
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>
        <div className='col-sm-5  col-md-4 col-lg-3 col-xl-2 '></div>

        <div className='col-sm-7  col-md-8 col-lg-9 col-xl-10 p-1 d-flex flex-column'>
          <img className='test-img-history2' src='.././images/gallery/nueva-zelanda/queenstown/queenstown.jpeg'></img>        
        </div>


    </div>

    <br></br>

</div>


<div className='container-fluid bg-dark'> <h1 className='text-white'>mapa</h1>

          <div className="test-svg-container svg-map position-absolute">
              <svg viewBox="0 0 360 180" className="border border-primary" height="100%" width="100%"> 


              {mapa.map((mapito, i)=>(
                     <circle key={i} cx={mapito[0]} cy={mapito[1]} r="3" stroke="white" strokeWidth="0.5"  fill="black" />

              ))}             
               <circle cx='170' cy='36' r="10" stroke="blue" strokeWidth="5"  fill="rgba(150,255,255,0.5" /> 
                
              </svg>     
           </div>                      

 

</div>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
{/*test SVG scalable Icons*/}
<div  className="svg-div border border-success position-relative">
<svg viewBox="0 0 180 180" className="" height="100%" width="100%"> 

<polygon points="0,0 0,180 180,180 180,0" fill="rgba(150,255,255,0.5"/>
<line x1="0" y1="0" x2="180" y2="180" stroke="red" strokeWidth="10" />
<circle cx='90' cy='90' r="30" stroke="purple" strokeWidth="30"  fill="rgba(150,255,255,0.5" /> 
                
</svg>  

</div>

{/*test SVG scalable Icons*/}
<div  className="svg-div border border-success position-relative">
<svg viewBox="0 0 100 100" className="" height="100%" width="100%"> 

<polygon points="9,97 9,29 3,22 3,4 7,4 7,10 14,10 14,4 19,4 19,10 27,10 27,4 31,4 31,10 31,22 25,29 25,54 33,54 33,48 43,48 43,54 57,54 57,48 67,48 67,54 75,54 75,29 69,22 69,4 74,4 74,10 81,10 81,4 86,4 86,10 93,10 93,4 98,4 98,22 92,29 92,97 63,97 63,84 37,84 37,97  " fill="black"/>
{/*<line x1="0" y1="0" x2="180" y2="180" stroke="red" strokeWidth="10" />
<circle cx='90' cy='90' r="30" stroke="purple" strokeWidth="30"  fill="rgba(150,255,255,0.5" /> 
              */}        
</svg>  

</div>




</div>
)}


