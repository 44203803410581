import React, { useEffect, useState }  from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { crearurl } from '../Utils';
import Hero from '../modules/HeroC';
import Info from '../modules/InfoC';
import Top from '../modules/TopC';
import Story from '../modules/StoryC';


const componente="pais"
let infohistoria=[]
let infotop=[]
let loaded=false
export default function Pais() {
/********************** */
const baseURL = `https://noderender1.onrender.com/${componente}`;
const [data, setData] = useState({id:0});
let { paramid } = useParams();
useEffect(() => {
  axios.get(baseURL,{params: {nombre: paramid}}).then((response) => {setData(response.data);infohistoria=response.data.historia;infotop=response.data.top} ).catch(err => console.log(err));}, [paramid]);
  console.log("dentro de Useeffect")
  console.log(data.id)
  if (data.id !==0){
        loaded=true
  } else {
        loaded=false
  }
  console.log("Ya se cargo Axios y loaded es "+loaded)

  /********************* */
  {if(loaded){
return(
<div>

<Hero imagen={`.././images/gallery/${crearurl(data.pais)}/${crearurl(data.pais)}.jpeg`} 
                    titulo={data.pais} 
                    subtitulo={data.titulo}
                    
                    imgstyle={data.imgstyle}
                    ranking={data.ranking} />

<Info continente={data.continente}
      pais={data.pais}
      ciudad={data.ciudad}
      atraccion={data.atraccion}
      subtitulo={data.titulo}
      breadcrumbs={['continente','pais']}
      />
     


     <Top 
      continente={data.continente}
      pais={data.pais}
      top={infotop}
      top_titulo="ciudad"
      ruta={`.././images/gallery/${crearurl(data.pais)}/`}
  />



    <h1>Pais</h1>
    <h1>{data.ciudad}</h1>
      <p>{data.pais}</p>
      <p>{data.id}</p>
      <p>{data.titulo}</p>
      <p>{data.descripcion}</p>
      <p>{data.continente}</p>

     
     
</div>
)}
  }}