import React, { useEffect, useState }  from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Hero from '../modules/HeroC';
import { crearurl } from '../Utils';
import Info from '../modules/InfoC';
import Story from '../modules/StoryC';
import Mapleaflet from '../modules/MapleafletC';




const componente="atraccion"
let infohistoria=[]
let loaded=false
console.log("cargando loaded "+loaded)
export default function Atraccion() {
/********************** */
const baseURL = `https://noderender1.onrender.com/${componente}`;
const [data, setData] = useState({id:0});
let { paramid, paramid2 } = useParams();
useEffect(() => {
      axios.get(baseURL,{params: {id: paramid2, nombre:paramid}}).then((response) => {setData(response.data);;infohistoria=response.data.historia;} ).catch(err => console.log(err));}, [paramid]);
      console.log("dentro de Useeffect")
      console.log(data.id)
      if (data.id !==0){
            loaded=true
      } else {
            loaded=false
      }
      console.log("Ya se cargo Axios y loaded es "+loaded)


/********************* */

{if(loaded){
return(

     
           
            
            
         
<div className='conatiner-fluid'>

<Hero imagen={`.././images/gallery/${crearurl(data.pais)}/${crearurl(data.ciudad)}/${crearurl(data.atraccion)}/${crearurl(data.atraccion)}.jpeg`} 
          titulo={data.atraccion} 
          subtitulo={data.titulo}
          imgstyle={data.imgstyle} 
          />


<Info continente={data.continente}
            pais={data.pais}
            ciudad={data.ciudad}
            atraccion={data.atraccion}
            subtitulo={data.titulo}
            breadcrumbs={['continente','pais','ciudad','atraccion']}
            />


<div className='container-xxl shadow p-0'>
<div className='row'>
      <div className='col-sm-4 p-4 col-lg-8  '>
            <h3>Informacion 2</h3>
            <i class="fas fa-building fs-1"></i>
            <h2>De Actualidad</h2>
            <br></br>
            <p>{data.descripcion}</p>
      </div>
      <div className='col-sm-8 p-1 col-lg-4  '>
            <div className='mapa-div'>
                  <Mapleaflet 
                  categoria={data.categoria}
                  markerPosition={{lat:data.lat,lng:data.lng}} 
                  center={{lat:data.lat,lng:data.lng}} 
                  />
            </div>
      </div>
      
</div>
</div>

<div className='container-lg'>
      
            {infohistoria.map((infostory, s)=>(
            <Story num={s} key={s} story={infostory} />
          

            ))}
     
</div>

    
    <h1>{data.ciudad}</h1>
      <p>{data.pais}</p>
      <p>{data.id}</p>
      <p>{data.titulo}</p>
      
      <p>{data.continente}</p>



     
</div>

)}
}}
