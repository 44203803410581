import React, { useEffect, useState }  from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Hero from '../modules/HeroC';
import Info from '../modules/InfoC';
import { crearurl } from '../Utils';
import Top from '../modules/TopC';

import Storycol from '../modules/StorycolC';
import Mapleaflet from '../modules/MapleafletC';
import Mapleafletciudad from '../modules/MapleafletCiudadC';


const componente="ciudad"
let infohistoria=[]
let infotop=[]
let loaded=false

export default function Ciudad() {
/********************** */
const baseURL = `https://noderender1.onrender.com/${componente}`;
const [data, setData] = useState({id:0});
let { paramid } = useParams();
useEffect(() => {
axios.get(baseURL,{params: {nombre: paramid}}).then((response) => {setData(response.data);infohistoria=response.data.historia;infotop=response.data.top} ).catch(err => console.log(err));}, [paramid]);
/********************* */
console.log("dentro de Useeffect")
console.log(data.id)
if (data.id !==0){
      loaded=true
} else {
      loaded=false
}
console.log("Ya se cargo Axios y loaded es "+loaded)



{if(loaded){
return(
<div>

<Hero imagen={`.././images/gallery/${crearurl(data.pais)}/${crearurl(data.ciudad)}/${crearurl(data.ciudad)}.jpeg`} 
                   titulo={data.ciudad} 
                   subtitulo={data.titulo}
                   
                   imgstyle={data.imgstyle}
                   ranking={data.ranking} />
<Info continente={data.continente}
      pais={data.pais}
      ciudad={data.ciudad}
      atraccion={data.atraccion}
      subtitulo={data.titulo}
      breadcrumbs={['continente','pais','ciudad']}
      />

<div className='container-xxl shadow p-0'>
<div className='row'>
      <div className='col-sm-4 p-4 col-lg-8  '>
            <h3>Informacion</h3>
            <h2>De Actualidad</h2>
            <br></br>
            <p>{data.descripcion}</p>
      </div>
      <div className='col-sm-8 p-1 col-lg-4  '>
            <div className='mapa-div'>
                  <Mapleafletciudad 
                  markerPosition={infotop} 
                  	
                  center={{lat:data.lat,lng:data.lng}}
                  />
            </div>
      </div>
      
</div>
</div>



<Top 
      continente={data.continente}
      pais={data.pais}
      ciudad={data.ciudad}
      top={infotop}
      top_titulo="atraccion"
      ruta={`.././images/gallery/${crearurl(data.pais)}/${crearurl(data.ciudad)}`}
  />

<div className='container-xxl border'>

  <h1>Historias Aqui</h1>   
<div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xxl-6'>
      
            {infohistoria.map((infostory, s)=>(
            <Storycol num={s} key={s} story={infostory} />
          

            ))}
     
</div>
</div>


    <h1>Ciudad</h1>
  
    <h1>{data.ciudad}</h1>
      <p>{data.pais}</p>
      <p>{data.id}</p>
      <p>{data.titulo}</p>
      <p>{data.descripcion}</p>
      <p>{data.continente}</p>





<br></br>



     
</div>
)}
}}