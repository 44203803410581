import { Link } from "react-router-dom";
import { crearurl } from '../Utils';

export default function Top(prop) {

/*
switch(prop.top_titulo)
{
        case "atraccion":
        ruta=`.././images/gallery/${crearurl(prop.pais)}/${crearurl(prop.ciudad)}`
        break;
        case "ciudad":
        ruta=`.././images/gallery/${crearurl(prop.pais)}`
        break;
        case "pais":
        ruta=`.././images/gallery/`
        break;
        
}    */   
/*`.././images/gallery/${crearurl(top.pais)}/${crearurl(top.ciudad)}/${crearurl(top.atraccion)}/${crearurl(top.atraccion)}.jpeg` */
{if(prop.tipo=="Continente"){
        console.log("mostrando Continente increible")
}}

return(
<div className="">
        <br></br>
    


<div className="container-xl border  d-flex flex-wrap">
{prop.top.map((top)=>(

<Link to={`/${prop.top_titulo}/${crearurl(top[prop.top_titulo])}`}>
        <div className="position-relative bg-light shadow m-2 p-2 " >
        
        
        <img  className={`top-image-${prop.top_titulo}`} alt={crearurl(top.titulo)} src={prop.ruta + `/${crearurl(top[prop.top_titulo])}/${crearurl(top[prop.top_titulo])}.jpeg`}></img>
        <div className="position-absolute bottom-0 p-0 d-flex text-white fs-2">
        {prop.tipo=="Continente" &&    <span className=" p-0 d-flex justify-content-center"><img className={`flag50 flag50-${crearurl(top.pais)}`} src=".././images/blank.png"></img></span>}
                {top[prop.top_titulo]}</div>
        </div>
</Link>
  
))}
</div>
<br></br>
</div>



)}