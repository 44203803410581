import React from 'react'


export default function Footer() {

return(

<div className='footer-container container-fluid d-flex justify-content-center py-3 px-5'>
    

    <i className="fab fa-facebook-f"></i>
    <i className="fab fa-instagram"></i>
    <i className="fab fa-twitter"></i>
    <i className="fab fa-youtube"></i>

</div>

)}
