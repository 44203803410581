import React, { useEffect, useState }  from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Hero from '../modules/HeroC';
import { crearurl } from '../Utils';
import Info from '../modules/InfoC';
import Story from '../modules/StoryC';
import Mapleaflet from '../modules/MapleafletC';



let infosub=[]
let infohistoria=[]
let infomas=[]
let loaded=false
console.log("cargando loaded "+loaded)
export default function Puntos() {
/********************** */
const baseURL = `https://noderender1.onrender.com/punto`;
const [data, setData] = useState({id:0});
let { paramid, tipo, titulo } = useParams();
useEffect(() => {
      axios.get(baseURL,{params: {id: paramid, tipo:tipo, nombre:titulo}}).then((response) => {setData(response.data);infosub=response.data.sub;infohistoria=response.data.historia;infomas=response.data.mas;} ).catch(err => console.log(err));}, [paramid]);
      console.log("dentro de Useeffect")
      console.log(data.id)
      if (data.id !==0){
            loaded=true
      } else {
            loaded=false
      }
      console.log("Ya se cargo Axios y loaded es "+loaded)


/********************* */

{if(loaded){
return(

     
           
            
            
         
<div className='container-fluid'>

<Hero imagen={`${data.imgurl}.jpeg`} 
          titulo={data.atraccion} 
          subtitulo={data.titulo}
          imgstyle={data.imgstyle} 
          />


<Info continente={data.continente}
            pais={data.pais}
            ciudad={data.ciudad}
            atraccion={data.atraccion}
            subtitulo={data.titulo}
            breadcrumbs={['continente','pais','ciudad','atraccion']}
            />





    
    <h1>{data.ciudad}</h1>
      <p>{data.pais}</p>
      <p>{data.id}</p>
      <p>{data.titulo}</p>
      
      <p>{data.continente}</p>



     
</div>

)}
}}
