import { crearurl } from '../Utils';

export default function Hero(prop) {

return(
   
<div className='container-fluid p-0 position-relative'>

    <div className='container-xxl hero-container position-relative p-0  d-flex justify-content-center'>
    <div className="position-absolute top-0 hero-fondo"><img className='hero-imagen' src={prop.imagen}></img></div>
    
    <div className='position-absolute bottom-0 hero-fondo-gradient'></div>

    <div className='position-absolute border border-dark shadow px-4 top-0 start-0 text-white fs-1'>{prop.ranking}</div>
<div className='position-absolute top-0 hero-fondo-filtro '></div>
       
        <div><img className='hero-imagen' src={prop.imagen}></img></div>
        
        <div className={` hero-style-${prop.imgstyle} text-white`}>
        <div> <img className={`flag100 flag100-${crearurl(prop.pais)}`} src=".././images/blank.png"></img></div>
            
            <h1 className="">{prop.titulo}</h1>
            <br></br>
            <br></br>
           
        </div>
       
    </div>
</div>




)}
