import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

//import icon from 'leaflet/dist/images/marker-icon.png';
//import iconShadow from 'leaflet/dist/images/marker-shadow.png';



const style = {
  width: "100%",
  height: "100%"
};

class Mapleaflet extends React.Component {
  componentDidMount() {
    // create map
    this.map = L.map("map", {
      center: this.props.center,
      zoom: 15,
      layers: [L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',)]
    });
   /* let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
      iconSize:     [60, 60], // size of the icon
      shadowSize:   [60, 60], // size of the shadow
      iconAnchor:   [0, 10], // point of the icon which will correspond to marker's location
      shadowAnchor: [0, 0],  // the same for the shadow
      popupAnchor:  [0, 50] // point from which the popup should open relative to the iconAnchor
  });*/
var icon_categoria="fa fa-star"
  if (this.props.categoria==="Castillo"){
    icon_categoria="fa fa-landmark"
  } else if(this.props.categoria==="Monumento"){
    icon_categoria="fa fa-monument"
  }else {
      icon_categoria="fa fa-hotel"}



  var DefaultIcon = L.divIcon({
    className: 'custom-div-icon ',
   // html: "<div style='background-color:#c30b82;' class='marker-pin'></div><i class='material-icons'>weekend</i>",
    html: `<Link to=www.google.com><div style='background-color:blue;' class='marker-pin'></div><i class='${icon_categoria}'></Link>`,
    //html: `<div style='background-color:#c30b82;' class='marker-pin'></div><svg viewBox="0 0 100 100" className="" height="100%" width="100%"> <polygon points="9,97 9,29 3,22 3,4 7,4 7,10 14,10 14,4 19,4 19,10 27,10 27,4 31,4 31,10 31,22 25,29 25,54 33,54 33,48 43,48 43,54 57,54 57,48 67,48 67,54 75,54 75,29 69,22 69,4 74,4 74,10 81,10 81,4 86,4 86,10 93,10 93,4 98,4 98,22 92,29 92,97 63,97 63,84 37,84 37,97  " fill="black"/></svg>`,
    
    iconSize: [30, 42],
    iconAnchor: [15, 42] // half of width + height
});
  
    // add marker
    this.marker = L.marker(this.props.markerPosition, {icon:DefaultIcon} , {win_url: "http://www.google.com"},).addTo(this.map);

  }
  /*componentDidUpdate({ markerPosition }) {
    // check if position has changed
    if (this.props.markerPosition !== markerPosition) {
      this.marker.setLatLng(this.props.markerPosition);
    }
  }*/
  
  render() {
    return <div id="map" style={style} />;
  }
}

export default Mapleaflet;
